<template>
  <paginated-masonry
    :id="masonryId"
    ref="masonry"
    :items="products"
    :loading="loading"
    class="mt-2"
    v-bind="theme.website.product.masonry"
  >
    <template v-slot:item="{ item }">
      <product-card :value="item" @redraw="onRedrawMasonry" />
    </template>
    <template v-slot:loading>
      <v-col cols="12" sm="4">
        <v-skeleton-loader elevation="2" type="card, card-actions" />
      </v-col>
    </template>
  </paginated-masonry>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import { makeRandomId } from '@peynman/press-vue-core/utils/helpers'
  import PaginatedMasonry from '@peynman/press-vue-admin/components/Inputs/PaginatedMasonry/PaginatedMasonry.vue'
  import ProductCard from './ProductCard/ProductCard.vue'

  export default {
    name: 'ProductsMasonry',
    components: {
      ProductCard,
      PaginatedMasonry,
    },
    mixins: [
      Themeable,
    ],
    props: {
      products: {
        type: Array,
        default: () => [],
      },
      loading: Boolean,
    },
    data: vm => ({
      dirty: false,
      lastRedraw: 0,
    }),
    computed: {
      masonryId () {
        return makeRandomId(10)
      },
    },
    watch: {
      dirty () {
        if (this.dirty && Date.now() - this.lastRedraw > 5000) {
          this.dirty = false
          this.lastRedraw = Date.now()
          console.log('redraw masonry: ' + this.lastRedraw)
          this.redraw()
        }
      },
    },
    methods: {
      redraw () {
        if (this.$refs?.masonry) {
          this.$refs.masonry.redraw()
        }
      },
      onRedrawMasonry () {
        this.dirty = true
      },
    },
  }
</script>
