<template>
  <v-card
    :dark="theme.website.product.card.dark"
    :light="theme.website.product.card.light"
    :color="theme.website.product.card.color"
    data-track-content
    data-content-piece="ProductQuickCart"
    :data-content-name="`Product-${productId}`"
    :data-content-target="`/shop/product/${productId}`"
  >
    <v-toolbar v-if="$vuetify.breakpoint.xs" tile>
      <v-toolbar-title>
        <router-link
          :to="`/shop/product/${productId}`"
          style="text-decoration: none; color: inherit;"
        >
          <span>{{ productTitle }}</span>
        </router-link>
      </v-toolbar-title>
      <v-spacer />
      <v-btn icon color="warning" @click="$emit('close')">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-row no-gutters align="center" justify="center">
      <v-col cols="12" sm="4">
        <v-card-text>
          <product-images-carousel
            :value="carousel"
            :images="carouselImages"
            :image-thumbnail-func="getImageThumbnail"
          />
        </v-card-text>
        <v-card-actions>
          <product-images-slider
            v-model="carousel"
            :images="carouselImages"
            :image-thumbnail-func="getImageThumbnail"
          />
        </v-card-actions>
      </v-col>
      <v-col cols="12" sm="8">
        <v-card-title v-if="!$vuetify.breakpoint.xs" class="py-1">
          <router-link
            :to="`/shop/product/${productId}`"
            style="text-decoration: none; color: inherit;"
          >
            <span>{{ productTitle }}</span>
          </router-link>
          <v-btn
            class="p-absolute"
            style="left: 5px;"
            dense
            small
            text
            :to="`/shop/product/${productId}`"
          >
            {{ $t('components.website.productCard.visit') }}
          </v-btn>
        </v-card-title>
        <div v-if="$vuetify.breakpoint.xs" class="d-flex flex-row justify-center align-center">
          <v-btn
            text
            :to="`/shop/product/${productId}`"
          >
            {{ $t('components.website.productCard.visit') }}
          </v-btn>
        </div>
        <v-divider />
        <v-card-subtitle class="py-2">
          <product-card-price :value="value" />
        </v-card-subtitle>
        <product-add-to-cart-form :value="value" @image-index="onChangeImage" />
        <v-divider v-if="$vuetify.breakpoint.xs" />
        <v-card-actions v-if="$vuetify.breakpoint.xs" class="d-flex flex-row justify-content align-center">
          <v-btn block @click="$emit('close')">
            <v-icon>mdi-close</v-icon>
            {{ $t('components.website.productCard.continueShopping') }}
          </v-btn>
        </v-card-actions>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
  import Product from '@peynman/press-vue-core/mixins/Product'
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import ProductCardPrice from './ProductCard/ProductCardPrice.vue'
  import ProductAddToCartForm from './ProductForm/ProductAddToCartForm.vue'
  import ProductImagesCarousel from './ProductCard/ProductImagesCarousel.vue'
  import ProductImagesSlider from './ProductCard/ProductImagesSlider.vue'

  export default {
    name: 'ProductAddToCartQuick',
    components: {
      ProductCardPrice,
      ProductAddToCartForm,
      ProductImagesCarousel,
      ProductImagesSlider,
    },
    mixins: [
      Product,
      Themeable,
    ],
    props: {
      value: Object,
    },
    data: vm => ({
      carousel: 0,
    }),
    methods: {
      onChangeImage (index) {
        this.carousel = index
      },
    },
  }
</script>
