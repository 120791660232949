<template>
  <v-card
    :dark="theme.website.product.card.dark"
    :light="theme.website.product.card.light"
    :color="theme.website.product.card.color"
    data-track-content
    data-content-piece="ProductCard"
    :data-content-name="`Product-${productId}`"
    :data-content-target="`/shop/product/${productId}`"
  >
    <product-card-ribbon :value="value" />
    <product-links
      v-if="!dense"
      :value="value"
      class="p-absolute"
      style="z-index: 1; left: 5px; top: 3px"
    />
    <product-images-carousel
      :images="carouselImages"
      :image-thumbnail-func="getImageThumbnail"
      :hide-arrows="dense"
      @show-image="onShowProductDetails"
      @redraw="$emit('redraw')"
    />
    <v-card-title
      class="text-subtitle-1 my-1 py-0 justify-center"
      style="position: relative"
    >
      <div style="position: absolute; top: -30px">
        <product-categories-subtitle
          v-if="!dense"
          :value="value"
          horizontal
          blend
        />
      </div>
      <router-link
        :to="`/shop/product/${productId}`"
        style="text-decoration: none; color: inherit"
      >
        <span
          :class="`${dense ? 'caption' : 'subtitle'} font-farsi capped-title`"
        >
          {{ productTitle }}
        </span>
      </router-link>
    </v-card-title>
    <v-card-subtitle :class="`${dense ? 'pb-3 ' : 'py-0'} my-1`">
      <product-card-price :value="value" />
    </v-card-subtitle>
    <v-card-actions
      v-if="!dense"
      class="d-flex flex-row justify-center align-center"
    >
      <v-btn text small :to="`/shop/product/${productId}`">
        {{ $t("components.website.productCard.details") }}
      </v-btn>
      <v-spacer />
      <v-dialog
        v-model="dialog"
        :fullscreen="$vuetify.breakpoint.xs"
        max-width="766"
      >
        <template v-slot:activator="dialogActivator">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn icon small v-on="{ ...on, ...dialogActivator.on }">
                <v-icon small>mdi-cart</v-icon>
              </v-btn>
            </template>
            {{ $t("components.website.productCard.addToCart") }}
          </v-tooltip>
        </template>
        <product-add-to-cart-quick :value="value" @close="dialog = false" />
      </v-dialog>
    </v-card-actions>
    <v-card-actions
      v-else
      class="d-flex flex-row justify-center align-center pt-0"
    >
      <v-btn outlined small :to="`/shop/product/${productId}`">
        {{ $t("components.website.productCard.purchase") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  import Themeable from '@peynman/press-vue-core/mixins/Themeable'
  import Product from '@peynman/press-vue-core/mixins/Product'
  import User from '@peynman/press-vue-core/mixins/User'
  import ProductCardPrice from './ProductCardPrice.vue'
  import ProductLinks from './ProductLinks.vue'
  import ProductCardRibbon from './ProductCardRibbon.vue'
  import ProductAddToCartQuick from '../ProductAddToCartQuick.vue'
  import ProductCategoriesSubtitle from './ProductCategoriesSubtitle.vue'
  import ProductImagesCarousel from './ProductImagesCarousel.vue'

  export default {
    name: 'ProductCard',
    components: {
      ProductCardPrice,
      ProductAddToCartQuick,
      ProductLinks,
      ProductCardRibbon,
      ProductCategoriesSubtitle,
      ProductImagesCarousel,
    },
    mixins: [Themeable, Product, User],
    props: {
      value: Object,
      dense: {
        type: Boolean,
        default: false,
      },
    },
    data: vm => ({
      dialog: false,
    }),
    methods: {
      onShowProductDetails () {
        this.$router.push(`/shop/product/${this.productId}`)
      },
    },
  }
</script>
